import React from 'react';


const ConsMod1 = () => {
  return(   
    <section class="section pb-0 bg-white mt4">
      <div class="container">
      
        <div class="row row-grid align-items-center shadow-1">
          <div class="col-md-6 order-lg-2 ml-lg-auto card center mt-2 border-0">
            <div class="tc ">
              <img class="img-center  img-fluid" alt='#' src="/images/efractie.png" width="350" height="auto"/>
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <div class="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <i class="ni ni-building text-primary"></i>
                </div>
              </div>              
            </div>            
            <div class="card shadow-lg--hover border-0 mt-2">
              <div class="card-body">
                <div class="d-flex px-3">
                  
                  <div class="pl-4">
                    <h5 class="title text-black">Sisteme de Alarma la Efractie</h5>
                    <p className='list tc'> Sistemele de alarma la efractie sunt prima etapa in asigurarea securitatatii casei, apartamentului sau afacerii tale. Acestea pot detecta patrunderea prin efractie, alerta local acustic si vizual, cat si sa transmita evenimentele catre un dispecerat specializat sau chiar catre mai multe telefoane mobile.
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
}

export default ConsMod1;