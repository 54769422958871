import React from 'react';
import '../InfoSite/InfoSite.css';

const Footer = () =>{
    return (
   <div className='Footer'>
  <footer style={{background:'url(http://i.giphy.com/5lF3pQpdquCBy.gif)'}} className="tc-l bg-center cover bg-black">
  <div class="w-100 ph3 pv3 bg-black-80">
    
    <small className="f6 db tc white">Copyright © 2019 <b class="ttu">Total Blue</b> - All Rights Reserved</small>
  </div>


</footer>
   </div>
    );
}

export default Footer;