import React, { Component } from 'react';
//import TopMenu from '../Components/Menu/Topmenu';
//import Footer from '../Components/Footer/Footer';


class Instalare extends Component {
  render() {
    return (
      <div className="center flex">   
       <article className="cf ph3 ph5-ns pv5">
  <header className="fn fl-ns w-60-ns pr4-ns">
    <p className="lh-copy  f4 b black">Instalare Profesionala a Sistemelor de Securitate</p>
    <p class="lh-copy tj">
      Instalare profesionala a sistemelor de securitate. Instalarea echipamentelor de supraveghere video, sisteme de alarma pentru casa, sisteme de alarma pentru 
      apartament, interfoane, video-interfoane sau detectie incendiu, este realizata conform planului. Plan rezultat in etapa proiectarii si respectand normele 
      legislatiei in vigoare. 
      Echipa noastra de profesionisti asigura o instalare de calitate si o functionare optima a echipamentelor si a sistemelor de securitate din 
      obiectivului securizat. 
      <p className="lh-copy tj">Toate sistemele de securitate, instalate de noi sunt certificate si beneficiaza de garantie.</p>
     <p class="lh-copy i b tc"> Cheia eficientei unui sistem de securitate o reprezinta instalarea. Te poti relaxa stiind ca afacerea, apartamentul sau casa ta
      sunt protejate de sistemele de securitate.</p>

     
    
    </p>
  </header>
  <div className="card border-0 fn fl-ns w-40-ns">
    <p className="lh-copy measure mt4 mt0-ns">
      <div className="center">
        <img className="card-img-top " alt='#' width='auto' display= 'flex' height='350px' src="/images/instalare.jpg"/>
      </div>
    </p>
    
  </div>

  <div className=''>
       
<p className="lh-copy b tc f3"> Tipuri de obiective Instalate</p>
     <p className="lh-copy tj"> Societatea este licentiata de Inspectorul General al Politiei Romane pentru a efectua lucrari de instalare, 
     intretinere a sistemelor de securitate pentru orice tip de obiectiv incluzand:</p>
     <p>
     <li className='ph4 tl'>Instalare si intretinere pentru Instituti de interes public : gari, aeroporturi, autogari, primarii, scoli etc.</li>
    <li className='ph4 tl'>Instalare si intretinere pentru Spatii comerciale.</li>
    <li className='ph4 tl'>Instalare si intretinere pentru Unitati financiar-bancare.</li>
    <li className='ph4 tl'>Instalare si intretinere pentru Depozite.</li>
    <li className='ph4 tl'>Instalare si intretinere pentru Clinici medicale.</li>
    <li className='ph4 tl'>Instalare si intretinere pentru Cazinouri.</li>
    <li className='ph4 tl'>Instalare si intretinere pentru Obiective industriale.</li>
    <li className='ph4 tl'>Instalare si intretinere pentru Apartamente.</li>
    <li className='ph4 tl'>Instalare si intretinere pentru Case/Vile.</li>
    <li className='ph4 tl'>Instalare si intretinere pentru Asociatii de Propietari.</li>
</p>
<p className="tc f3 ph4 b">Instalare si punere in functiune a sistemelor de securitate</p>
<p className="lh-copy tj"> Seviciile de instalare a sistemelor de securitate includ: </p>
     <p>
     <li className='ph4 tl'>Organizarea santierului.</li>
    <li className='ph4 tl'>Realizarea cablajului. </li>
    <li className='ph4 tl'>Montajul echipamentelor. </li>
    <li className='ph4 tl'>Verificarea sistemului. </li>
    <li className='ph4 tl'>Activarea sistemului si Punerea in Functiune.</li>
   
</p>


<p className="tc b f3">Instalam Sisteme de securitate conform normelor impuse in domeniu</p>
<p className="tc">Instalam sisteme de securitate ce urmeaza sa respecta cu stricteţe normele impuse in documentatia tehnico-economica, dar si reglementarile
 legislative din domeniu. Instalarea sistemelor de securitate se face cu o echipa de profesionisti. Cat si evaluarea obiectivului, instalarea completa si 
 testarea alarmelor, a camerelor de supraveghere video, a detectoarelor si a tuturor celorlalte echipamente ce fac parte din sistemul de securitate ales.
  Sistemele de securitate trebuie sa fie instalate numai de profesionisti pentru a asigura o buna functionare a acestora si pentru a avea beneficii 
  indelungate pentru obiectivul securizat. Executarea instalatiilor de semnalizare a incendiilor si a sistemelor de alarma impotriva efractiei din cladiri,
   case sau apartamente, a camerelor de supraveghere video, toate acestea sunt reglementate de normative, legi si standarde si pot fi instalate doar de 
   societatile licentiate.</p>
<p className="tc b f3">Instalare sisteme de securitate</p>
<p className="tc">Instalam sisteme de securitate si vindem sisteme de : supraveghere video, control acces si pontaj cu cartele de proximitate si cititoare de 
amprente, alarma la efractie, alarma la incendiu, automatizari porti batante si culisante, centrale telefonice, sisteme cu porti antifurt magazine,
 video-interfoane. Instalam si vindem: camere de supraveghere video de exterior si interior, camere de supraveghere ip, camere de supraveghere wifi, 
 videorecordere digitale tip dvr si nvr, camere de supraveghere de tip speed dome si box, interfoane, detectori miscare, detectori fum, centrale de alarmare 
 la efractie si incendiu etc.</p>

           </div>
</article>

      </div>
    );
  }
}

export default Instalare;