import React, { Component } from 'react';

//import TopBar from '../Components/Menu/TopBar';
//import TopMenu from '../Components/Menu/Topmenu';
//import TopMenu from '../Components/Menu/Topmenu';
import Slider from '../Components/Slider/Slider';
import ConsMod from '../Components/InfoSite/ConsMod';
import ConsMod1 from '../Components/InfoSite/ConsMod1';
import ConsMod2 from '../Components/InfoSite/ConsMod2';
import ConsMod3 from '../Components/InfoSite/ConsMod3';
import ConsMod4 from '../Components/InfoSite/ConsMod4';
import ConsMod5 from '../Components/InfoSite/ConsMod5';
import ConsMod6 from '../Components/InfoSite/ConsMod6';
import ConsMod7 from '../Components/InfoSite/ConsMod7';
import Infintare from '../Components/InfoSite/Infintare';
import CardModul from '../Components/InfoSite/CardModul';
//import Footer from '../Components/Footer/Footer';


class Home extends Component {
  render() {
    return (
      <div className="">
      <Slider />
      <CardModul />
      <ConsMod />
      <ConsMod1 />
      <ConsMod2 />
      <ConsMod3 />
      <ConsMod4 />
      <ConsMod5 />
      <ConsMod6 />
      <ConsMod7 />
      <Infintare />      
      </div>
    );
  }
}

export default Home;