import React, { Component } from 'react';
//import TopMenu from '../Components/Menu/Topmenu';
//import Footer from '../Components/Footer/Footer';


class Mentenanta extends Component {
  render() {
    return (
      <div className="center flex">   
       <article className="cf ph3 ph5-ns pv5">
  <header className="fn fl-ns w-60-ns pr4-ns">
    <p className="lh-copy  f2 b black">Servicii de mentenanta/intretinere a sistemelor de securitate</p>
    <p class="lh-copy tj">
      Functionarea corecta a sistemelor de securitate, indiferent de marime sau complexitate, necesita inspectii periodice.
       Prin verificarile periodice si reparatia sau inlocuirea partilor defecte, propunem o abordare proactiva asupra serviciilor
        de mentenanta care asigura o mai buna functionare continua a sistemului de securitate si care mentine intreruperile sistemului la un nivel minim.</p>
      <p className="lh-copy tc b f3">De ce avem nevoie de servicii de mentenanta?</p>
     <p class="lh-copy tc"> Conform Art. 22, din normele tehnice care trebuie respectate cu ocazia proiectarii si realizarii sistemelor de securitate:</p>
     <li className='ph4 tl'>Sistemele de alarmare impotriva efractiei trebuie periodic verificate si intretinute de personalul firmei instalatoare, 
     sau dupa caz, de firma care asigura service-ul.</li>
     <li className='ph4 tl'>Beneficiarii sistemelor supuse avizarii sunt obligati sa incheie contract de service cu societati licentiate.</li>
  </header>
  <div className="card border-0 fn fl-ns w-40-ns">
    <p className="lh-copy measure mt4 mt0-ns">
      <div className="center">
        <img className="card-img-top " alt='#' width='auto' display= 'flex' height='400px' src="/images/mentenanta.jpg"/>
      </div>
    </p>
    
  </div>

  <div className=''>
      <p className="white "> .</p> 
<p className="lh-copy b tc f3"> Principalele avantaje ale serviciilor noastre:</p>
     <p className="lh-copy tj"> Societatea este licentiata de Inspectorul General al Politiei Romane pentru a efectua lucrari de mentenanta/intretinere
      a sistemelor de securitate.</p>
     <p>
     <li className='ph4 tl'><b>Suport Tehnic Gratuit </b>– Specialistii nostri iti ofera suport tehnic atunci cand intampini dificultati. Daca problema
      nu poate fi rezolvata telefonic, echipa noastra de interventii se va deplasa la locatia obiectivului in cel mai scurt timp posibil.</li>
    <li className='ph4 tl'><b>Verificari Trimestriale </b>– Technicienii nostri vor efectua vizite periodice pentru verificarea parametrilor de functionare
     si testare a echipamentelor.</li>
    <li className='ph4 tl'><b>Reparatii in regim de urgenta</b> – In cazul constatarii unei defectiuni a componentelor sistemelor de securitate, operatiunile de reparatie
     sau inlocuire vor fi efectuate in regim de urgenta.</li>
    <li className='ph4 tl'><b>Prioritatea pentru service</b> – Pentru clientii care au in derulare contracte de mentenata se asigura prioritate pentru reparatiile realizate in service.</li>
    <li className='ph4 tl'><b>Asigurarea pieselor de schimb</b> – Atunci cand o componenta necesita inlocuire, piesa de schimb este oferita din stoc sau procurata in regim de urgenta de la colaboratorii nostri.</li>
    <li className='ph4 tl'><b>Experienta profesionala</b> – Inginerii si tehnicienii nostrii dispun de competente certificate.</li>
    <li className='ph4 tl'><b>Echipamente de back-up</b> – Pentru a nu sista functionarea sistemelor de securitate si, in unele cazuri, a activitatilor companiei in cazul defectarii unui 
    echipament, garantam inlocuirea lui pe perioda reparatiei.</li>
</p>


           </div>
</article>

      </div>
    );
  }
}

export default Mentenanta;