import React from 'react';
import {Link} from 'react-router-dom';
import './TopMenu.css';
import {
  Collapse,
  Navbar,
  NavbarToggler,  
  Nav,
  NavItem,
  NavLink
  } from 'reactstrap';

export default class TopMenu extends React.Component {
 
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      isOpen: false,
      dropdownOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  toggleMenu(){
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));

  }  

   onMouseEnter() {
    this.setState({dropdownOpen: true});
  }

  onMouseLeave() {
    this.setState({dropdownOpen: false});
  }

  render() {
    return (
      <div className=' '>
      <nav className="flex justify-between bg-navy" >   
    <div className="flex-grow align-items-center ph3 ph3-ns mr3 ">
        
        <svg xmlns="http://www.w3.org/2000/svg" 
        width="24" height="24" 
        viewBox="0 0 24 24" fill="none" stroke="currentColor" 
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" 
        className="feather feather-mail hover-white no-underline white-70 ">
        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
        </path><polyline points="22,6 12,13 2,6"></polyline></svg>
         <a href="mailto:office@tbsystems.ro" className='hover-white  no-underline white-70 ph2' >
         office@tbsystems.ro</a>  
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" 
         stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone hover-white no-underline white-70 ">
         <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
         <span className='hover-white  no-underline white-70 ph2 pointer'>
         0737761100</span>  
         
         
        
    </div>
    
        
    </nav>    

        <Navbar className='shadow-5 bg-white' light expand="md">
<div className='flex-grow flex-end tl ph3 ph3-ns mr3 pointer' >
<span className='ph2'><img alt='#' src="/images/Sigla.JPG" className='pointer' width="85" height="55" /></span>

</div>
          <NavbarToggler onMouseOver={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
               <NavItem>
                <NavLink tag={Link} to="/home" className='f4 pointer b blue hover-green'>Home</NavLink>
              </NavItem>
                <NavItem>
                <NavLink tag={Link} to="/evaluare" className='f4 pointer b blue hover-green'>Evaluare</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/proiectare" className='f4 pointer b blue hover-green'>Proiectare</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/instalare" className='f4 pointer b blue hover-green'>Instalare</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/mentenanta" className='f4 pointer b blue hover-green'>Mentenanta</NavLink>
              </NavItem>
              
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
