import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';

const items = [
  {
    src: '/images/police.jpg',
    altText: 'Slide 1',
    caption: 'Proiectare - Instalare - Consultanta - Mentenanta',
    header: 'SOLUTII SI SISTEME DE SECURITATE'
  },
  {
    src: '/images/hug.jpg',
    altText: 'Slide 2',
    caption: 'Proiectare - Instalare - Consultanta - Mentenanta',
    header: 'SOLUTII SI SISTEME DE SECURITATE'
  },
  {
    src: '/images/big.jpg',
    altText: 'Slide 3',
    caption: 'Proiectare - Instalare - Consultanta - Mentenanta',
    header: 'SOLUTII SI SISTEME DE SECURITATE'
  }
];

const Slider = () => <UncontrolledCarousel items={items} className='shadow-5' />;

export default Slider;