import React from 'react';
import {Link} from 'react-router-dom';
import {
  Button } from 'reactstrap';

const CardModul = () => {
  return(     

  
    <section class="section section-lg pt-lg-0 mt--200 mt4">
      <div class="container">
      <div class=""> 
      <h2 class="title f1 navy">
Sisteme de Securitate:
</h2>
      <h2 class="title f3 navy">
Sisteme de Alarma Antiefractie, Sisteme Detectie la Incendiu, Sisteme Supraveghere Video, Sisteme Control Acces, Sisteme Video-Interfonie, Sisteme Antifurt Magazine, Retele Voce si Date, Instalare, Intretinere, Service. 

</h2></div>
        <div class="row justify-content-center mt4">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-3">
                <div class="card card-lift--hover shadow border-0 grow">
                <div class="center">
                       <img class="card-img-top " alt='#' width='auto' display= 'flex' height='220px' src="/images/risc.jpg"/>
                    </div>
                  <div class="card-body py-5">                    
                    <h6 class="text-primary text-uppercase">Evaluare de risc la securitatea fizica</h6>
                    <p class="description mt-3">Evaluarea de risc la securitatea fizica este obligatorie pentru toate societatile care detin cel putin un punct de lucru.</p>                    
                    <Button tag={Link} to="/evaluare" className="bg-blue hover-bg-blue border-0 mt-4 pointer"> Citeste</Button>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="card card-lift--hover shadow border-0 grow">
                <div class="center">
                       <img class="card-img-top" alt='#' width='auto' display= 'flex' height='220px' src="/images/proiectare.jpg"/>
                    </div>
                  <div class="card-body py-5">                   
                    <h6 class="text-primary text-uppercase">Proiectare sisteme securitate</h6>                    
                    <p class="description mt-3">  Executam proiectul tehnic pentru sistemele de securitate. Intocmim toate documentele.</p>                    
                   <Button tag={Link} to="/proiectare" className="bg-blue hover-bg-blue border-0 mt-4 pointer"> Citeste</Button>
                  </div>
                </div>
              </div>
               <div class="col-lg-3">
                <div class="card card-lift--hover shadow border-0 grow">
                <div class="center">
                       <img class="card-img-top" alt='#' width='auto' display= 'flex' height='220px' src="/images/instalare.jpg"/>
                    </div>
                  <div class="card-body py-5">                   
                    <h6 class="text-primary text-uppercase">Instalare sisteme de securitate</h6>
                    <p class="description mt-3">Pentru siguranta afacerii apelati la instalarea sistemelor de securitate de catre expertii nostri.</p>                    
                    <Button tag={Link} to="/instalare" className="bg-blue hover-bg-blue border-0 mt-4 pointer"> Citeste</Button>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="card shadow border-0 grow">
                <div class="center">
                       <img class="card-img-top " alt='#' width='auto' display= 'flex' height='220px' src="/images/mentenanta.jpg"/>
                    </div>
                  <div class="card-body py-5">                   
                    <h6 class="text-primary text-uppercase">Mentenanta sisteme de securitate</h6>
                    <p class="description mt-3">Total Blue ofera servicii profesionale de mentenanta pentru orice sistem de securitate instalat.</p>
                    <Button tag={Link} to="/mentenanta" className="bg-blue hover-bg-blue border-0 mt-4 pointer"> Citeste</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardModul;