import React from 'react';


const ConsMod1 = () => {
  return(   
  	<section class="section pb-0 bg-white mt4">
      <div class="container">
      
        <div class="row row-grid align-items-center shadow-1">
          <div class="col-md-6 order-lg-2 ml-lg-auto card center mt-2 border-0">
            <div class="tc ">
              <img class="img-center  img-fluid" alt='#' src="/images/incendiu.png" width="300" height="auto"/>
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <div class="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <i class="ni ni-building text-primary"></i>
                </div>
              </div>              
            </div>            
            <div class="card shadow-lg--hover border-0 mt-2">
              <div class="card-body">
                <div class="d-flex px-3">
                  
                  <div class="pl-4">
                    <h5 class="title text-black">Sisteme Detectie la Incendiu</h5>
                    <p className='list tc'>Protectia la incediu este o masura obligatorie pentru toate cladirile, indiferent de obiectul de activitate, insa pentru anumite categorii dintre acestea, este obligatorie si instalarea unui sistem de detectie si alarmare la incediu, in baza unui proiect realizat de catre o firma avizata IGSU
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
}

export default ConsMod1;