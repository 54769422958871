import React, { Component } from 'react';
//import TopMenu from '../Components/Menu/Topmenu';
//import Footer from '../Components/Footer/Footer';


class Evaluare extends Component {
  render() {
    return (
      <div className="center flex">   
       <article className="cf ph3 ph5-ns pv5">
  <header className="fn fl-ns w-70-ns pr4-ns">
    <p className="lh-copy  f4 b black">Evaluarea de risc la securitatea fizica este obligatorie pentru toate societatile comerciale care detin cel putin un punct de lucru autorizat.</p>
    <p class="lh-copy measure tl">
      Procesul de evaluare a riscului cuprinde urmatoarele faze:
     
     <li className='ph4'>stabilirea contextului</li>
    <li className='ph4'>identificarea riscului</li>
    <li className='ph4'>analiza riscului</li>
    <li className='ph4'>estimarea riscului</li>
    <li className='ph4'>tratarea riscului</li>

    </p>
  </header>
  <div className="card border-0 fn fl-ns w-30-ns">
    <p className="lh-copy measure mt4 mt0-ns">
      <div className="center">
        <img className="card-img-top " alt='#' width='auto' display= 'flex' height='250px' src="/images/risc.jpg"/>
      </div>
    </p>
    
  </div>

  <div className=''>
       

<p className="tj ph4 b">ATENTIE!!! Analiza de risc la securitatea fizica este obligatorie.</p>
<p className="tj">Legislatia in domeniul securitatii prevede unele masuri absolut obligatorii.
Hotărârea Guvernului nr. 301/2012 actualizată conform Hotărârii nr. 1002/2015 pentru aprobarea normelor metodologice de aplicare a 
legii 333/2003 privind paza obiectivelor, bunurilor, valorilor si protectia persoanelor.</p>
<p className="tj">Analiza de Risc are ca scop identificarea factorilor interni si externi ce afecteaza in mod direct sau indirect securitatea fizica a persoanelor 
care isi desfasoara activitatea in cadrul unei unitati.</p>
<p className="tj">Sistemele de securitate generate in urma Analizei de risc la securitatea fizica, trebuie implementate in maxim 60 de zile de catre unitatile ce fac 
obiectul unei evaluarii.</p>
<p className="tj">In urma implementarii sistemelor de securitate conducatorii au obligatia sa asigure mentenanta acestor numai prin firme specializate si autorizate 
cel putin odata la 6 luni pentru asigurarea functionalitatii acestora.</p>
<p className="tj">Incalcarea prevederilor din legislatia specifica privind elaborarea si implementarea analizei de risc la securitatea fizica constituie contraventie 
si se sanctioneaza cu amenda de la 5000 la 10000 RON </p>

<p className="tj b ph4">De ce este necesara analiza de risc la securitatea fizica ?</p>
<p className="tj">Prevăzută de Legea 333 / 2003, si HG 301 / 2012 este necesară pentru:</p>
<p>
<li className='tl ph4'>protecția persoanelor, paza bunurilor și a valorilor.</li>
<li className='tl ph4'>stabilirea măsurilor de securitate prin mijloace mecanofizice si tehnice precum si personal specializat.</li>
<li className='tl ph4'>avizarea proiectului tehnic pentru sistemele de securitate.</li></p>

<p className="tj b ph4">Ce documente se întocmesc și care este termenul de valabilitate?</p>
<p className="tj">Documentația privind analiza de risc la securitatea fizică conține:</p>
<p>
<li className='tl ph4'>raportul de evaluare și tratare a riscurilor la securitatea fizica</li>
<li className='tl ph4'>grila de evaluare a nivelului de risc</li>
<li className='tl ph4'>documentele suport</li>
</p>
<p className="tj">Raportul de evaluare la securitatea fizica este valabil 3 ani, daca nu au avut loc incidente de securitate, schimbari de sedii sau recompartimentari.</p>
           </div>
</article>

      </div>
    );
  }
}

export default Evaluare;