import React, { Component } from 'react';
//import TopMenu from '../Components/Menu/Topmenu';
//import Footer from '../Components/Footer/Footer';


class Proiectare extends Component {
  render() {
    return (
      <div className="center flex">   
       <article className="cf ph3 ph5-ns pv5">
  <header className="fn fl-ns w-60-ns pr4-ns">
    <p className="lh-copy  f4 b black">PROIECTARE SISTEME DE SECURITATE</p>
    <p className="lh-copy tl">
      Oferim servicii de proiectare sisteme de securitate atat pentru cladiri/spatii noi cat si pentru sisteme ce necesita modernizare sau inlocuire completa.</p>
    <p className="lh-copy tl">  Proiectul de instalare a sistemului de securitate instalat, este un document suport in vederea intocmirii evaluarii de risc la securitata fizica. In situatia 
      in care nu detineti un proiect de instalare, acest proiect se intocmeste in baza evaluarii de risc de catre un proiectant/societate avizata in acest sens.</p>
    <p className="lh-copy tl">
    <p className="lh-copy tl b">Servicii de proeictare oferite: </p>
     <li className='ph4'> proiectare sisteme de securitate integrate</li>
    <li className='ph4'>proiectare sisteme de alarma</li>
    <li className='ph4'>proiectare sisteme de supraveghere video</li>
    <li className='ph4'>proiectare sisteme de control acces</li>
    <li className='ph4'>proiectare sisteme de securitate perimetrala</li>
    <li className='ph4'>proiectare de detectie furturi din magazine</li>

    </p>
  </header>
  <div className="card border-0 fn fl-ns w-40-ns">
    <p className="lh-copy measure mt4 mt0-ns">
      <div className="center">
        <img className="card-img-top shadow-5" alt='#' width='auto' display= 'flex' height='450px' src="/images/proiectare.jpg"/>
      </div>
    </p>
    
  </div>

  <div className=''>
       

<p className="tj ph4 b">BAZA LEGALA:</p>
<p className="tj b">Art. 11, Cap. III Norme tehnice Legea 333/2003
</p>
<p className="tj">” Proiectele sistemelor de alarmare vor fi intocmite de personalul tehnic avizat al societatilor licentiate in acest sens, cu respectarea prezentelor 
norme tehnice. Proiectarea aplicatiilor cu sisteme de alarmare impotriva efractiei se realizeaza cu respectarea normelor in domeniu si a celor proprii unitatilor, 
tinandu-se seama de gradul de protectie stabilit de conducerea unitatilor in functie de valorile ce trebuie protejate (umane si materiale), existenta si situarea zonelor
 vitale, caile de acces, circulatia personalului propriu si al clientilor, amenajarile mecano-fizice realizate, tipul pazei, amplasarea dispozitivului de paza si departarea 
 fata de echipajul specializat de interventie cu care unitatea are contract de prestari servicii. ”</p>
<p className="tj b ph4">OBLIGATIVITATE</p>
<p className="tj">Unitatile (persoanele juridice) care sunt obligate prin lege sa instaleze sisteme de alarma si de supraveghere video in baza unui proiect avizat de catre
 Politie sunt urmatoarele:</p>
<p>
<li className='tl ph4'>Unitati de interes strategic</li>
<li className='tl ph4'>Unitati de interes public.</li>
<li className='tl ph4'>Unitati de creditare</li>
<li className='tl ph4'>Magazine activitati cu bijuterii.</li>
<li className='tl ph4'>Sali jocuri de noroc</li>
<li className='tl ph4'>Casierii si servicii utilitati.</li>
<li className='tl ph4'>Unitati postale</li>
<li className='tl ph4'>Puncte de schimb valutar.</li>
<li className='tl ph4'>Case de amanet</li>
<li className='tl ph4'>Obiective industriale.</li>
<li className='tl ph4'>Depozite</li>
<li className='tl ph4'>Instalatii tehnologice.</li></p>


<p className="tj b ph4">In urma efectuarii analizei de risc la securitate fizica este posibil ca evaluatorul sa propuna instalarea unui sistem de securitate cu proiect
 avizat daca riscurile identificate impun acest lucru. Evaluarea de risc este obligatorie incepand cu 1 Iulie 2014.</p>



           </div>
</article>

      </div>
    );
  }
}

export default Proiectare;