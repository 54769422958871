import React, { Component } from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import Home from './Containers/Home';
import Evaluare from './Containers/Evaluare';
import Proiectare from './Containers/Proiectare';
import Instalare from './Containers/Instalare';
import Mentenanta from './Containers/Mentenanta';
//import Logo from './Components/Logo/Logo';
//import Navigation from './Components/Navigation/Navigation';
import TopMenu from './Components/Menu/Topmenu';
//import Slider from './Components/Slider/Slider';
//import TopModul from './Components/InfoSite/TopModul';
//import Consultanta from './Components/InfoSite/Consultanta';
//import CardCombustibil from './Components/InfoSite/CardCombustibil';
//import ConsMod from './Components/InfoSite/ConsMod';
//import Infintare from './Components/InfoSite/Infintare';
//import Card from './Components/Card/Card';
import Footer from './Components/Footer/Footer';
//import CardModul from './Components/InfoSite/CardModul';
//import TopBar from './Components/Menu/TopBar';

import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
      
      <TopMenu />
<Switch>
      <Route exact path="/"  component={Home}  />
      <Route  path="/evaluare" component={Evaluare}  />
      <Route  path="/proiectare" component={Proiectare}  />
      <Route  path="/instalare" component={Instalare}  />
      <Route  path="/mentenanta" component={Mentenanta}  />
      <Redirect to='/' />
</Switch>                                        
      <Footer />
      </div>
    );
  }
}

export default App;
