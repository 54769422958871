import React from 'react';
import './InfoSite.css';

const Infintare = () => {
  return(       
    <section class="section section-lg bg-light-gray mt6">
      <div class="container pt-lg pb-300">
        <div class="row text-center justify-content-center">
          <div class="col-lg-10">
            <h2 class="display-5 text-blue b ma4">Evaluarea de risc la securitatea fizica este obligatorie pentru toate societatile comerciale care detin cel putin un punct de lucru autorizat.</h2>
            <p class="lead text-black">In conformitate cu prevederile Hotărârea Guvernului nr. 301/2012 actualizată conform Hotărârii nr. 1002/2015 pentru aprobarea normelor metodologice de aplicare a legii 333/2003 privind paza obiectivelor, bunurilor, valorilor si protectia persoanelor. </p>
          </div>
        
          <h3 class="text-black f3 b mt-3">Cheia eficientei unui sistem de securitate o reprezinta instalarea. Te poti relaxa stiind ca afacerea, 
          apartamentul sau casa ta sunt protejate de sistemele de securitate.</h3>
        </div>

      </div>
     
            
     </section>

  );
}

export default Infintare;