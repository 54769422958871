import React from 'react';


const ConsMod1 = () => {
  return(   
  	<section class="section pb-0 bg-white mt4">
      <div class="container">
      
        <div class="row row-grid align-items-center shadow-1">
          <div class="col-md-6 order-lg-2 ml-lg-auto card center mt-2 border-0">
            <div class="tc ">
              <img class="img-center  img-fluid" alt='#' src="/images/surveillance.png" width="300" height="auto"/>
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <div class="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <i class="ni ni-building text-primary"></i>
                </div>
              </div>              
            </div>            
            <div class="card shadow-lg--hover border-0 mt-2">
              <div class="card-body">
                <div class="d-flex px-3">
                  
                  <div class="pl-4">
                    <h5 class="title text-black">Sisteme Supraveghere Video</h5>
                    <p className='list tc'>Sistemele de supraveghere video sunt cea mai populara metoda de protectie, acest lucru datorandu-se faptului ca imaginile provenite de la camerele de supraveghere pot fi urmarite prin internet, de pe calculator sau chiar de pe telefonul mobil, fiind folosite ca si sistem antifurt, cat si pentru supravegherea activitatii personalului.
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
}

export default ConsMod1;