import React from 'react';


const ConsMod1 = () => {
  return(   
  	<section class="section pb-0 bg-white mt4">
      <div class="container">
      
        <div class="row row-grid align-items-center shadow-1">
          <div class="col-md-6 order-lg-2 ml-lg-auto card center mt-2 border-0">
            <div class="tc ">
              <img class="img-center  img-fluid" alt='#' src="/images/interfon.png" width="250" height="auto"/>
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <div class="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <i class="ni ni-building text-primary"></i>
                </div>
              </div>              
            </div>            
            <div class="card shadow-lg--hover border-0 mt-2">
              <div class="card-body">
                <div class="d-flex px-3">
                  
                  <div class="pl-4">
                    <h5 class="title text-black">Sisteme Video-Interfonie</h5>
                    <p className='list tc'>Videointerfonul sau Interfonul este tot un sistem de control al accesului, nu poate genera rapoarte complexe, insa permite identificarea vizuala a apelantului sau chiar inregistrarea video a vizitatorilor. Aceste sisteme sunt foarte populare in mediul rezidential (blocuri, vile, etc), facilitand deschiderea de la distanta a portilor de acces.
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
}

export default ConsMod1;