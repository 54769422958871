import React from 'react';


const ConsMod1 = () => {
  return(   
  	<section class="section pb-0 bg-white mt4">
      <div class="container">
      
        <div class="row row-grid align-items-center shadow-1">
          <div class="col-md-6 order-lg-2 ml-lg-auto card center mt-2 border-0">
            <div class="tc ">
              <img class="img-center  img-fluid" alt='#' src="/images/service.png" width="300" height="auto"/>
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <div class="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <i class="ni ni-building text-primary"></i>
                </div>
              </div>              
            </div>            
            <div class="card shadow-lg--hover border-0 mt-2">
              <div class="card-body">
                <div class="d-flex px-3">
                  
                  <div class="pl-4">
                    <h5 class="title text-black">Instalare, Intretinere, Service</h5>
                    <p className='list tc'>Ai un proiect nou si nu ai contractat inca o societate pentru realizarea serviciilor mai sus mentionate? Nimic mai simplu! Contacteaza-ne si noi putem realiza de la zero, avizare, instalare, punere in functiune si mentenanta, cu o minima implicare din partea dumneavoastra!
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
}

export default ConsMod1;